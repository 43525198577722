<template>
  <div class="convenientService">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot
        ><v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="发布人" v-model="searchParam.broadcaster"></v-input>
        <v-select
          clearable
          label="状态"
          v-model="searchParam.status"
          :options="auditStatusOps"
          placeholder="请选择"
        />
        <v-select
          clearable
          label="服务类别"
          v-model="searchParam.serviceType"
          :options="severTypeList"
          placeholder="请选择"
          @change="handelChangeServeType"
        />
        <v-select
          v-if="searchParam.serviceType"
          clearable
          label="子分类"
          v-model="searchParam.serviceTypeLv2"
          :options="detailSeverTypeList"
          placeholder="请选择"
        />
        <v-input label="服务标题" v-model="searchParam.title"></v-input>
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="editData(scope.row.id)" />
        <v-button
          v-if="scope.row.status == 0"
          text="审核"
          type="text"
          @click="auditData(scope.row.id)"
        />
        <!-- <v-button
          v-if="scope.row.status == 1"
          disabled
          text="审核通过"
          type="text"
          @click="auditData(scope.row.id)"
        /> -->
        <v-button text="删除" type="text" @click="deleteData(scope.row.id)" />
      </template>
    </v-list>

    <v-dialog v-model="isDialog" title="审核" sureTxt="保存" @confirm="submit">
      <el-form label-position="right" label-width="150px">
        <el-form-item label="审核状态">
          <v-select :options="statusOps" v-model="dialogParma.status" />
        </el-form-item>
        <el-form-item label="审核备注">
          <v-input type="textarea" v-model="dialogParma.auditRemarks"></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  getServiceList,
  getInitInfo,
  deleteService,
  examineService,
  getServiceTypeLv2,
} from "./api.js";
import { createImgVNode } from "@/utils/utils.js";
import { auditStatusOps, auditStatusMap } from "./map.js";
export default {
  name: "convenientService",
  data() {
    return {
      isDialog: false,
      dialogParma: {
        id: "",
        status: "",
        auditRemarks: "",
      },
      searchParam: {
        broadcaster: "", //发布人
        status: "", //状态
        serviceType: "", //服务类别
        serviceTypeLv2: "", //服务类别
        title: "", //服务标题
        createTimeS: "", //开始时间
        createTimeE: "", //结束时间
      },
      statusOps: [
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核不通过",
        },
      ],
      auditStatusOps: auditStatusOps(), //审核状态
      tableUrl: getServiceList,
      severTypeList: [],
      detailSeverTypeList: [],
      headers: [
        {
          prop: "coverPicture",
          label: "服务封面",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },

        {
          prop: "title",
          label: "服务标题",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "broadcaster",
          label: "发布人",
        },
        {
          prop: "contactMobile",
          label: "联系方式",
        },
        {
          prop: "serviceTypeName",
          label: "服务类别",
        },
        {
          prop: "serviceTypeLv2Name",
          label: "详情服务",
        },
        {
          prop: "status",
          label: "状态",
          formatter: (row, prop) => auditStatusMap[row[prop]],
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  created() {
    this.getInitInfo();
  },
  methods: {
    // 选择服务类别
    handelChangeServeType(value) {
      this.searchParam.serviceTypeLv2 = "";
      this.getServiceTypeLv2(value);
    },
    //获取小类数据
    getServiceTypeLv2(code) {
      this.$axios.get(`${getServiceTypeLv2}?code=${code}`).then((res) => {
        if (res.code == 200) {
          const { list } = res.data;
          list.forEach((item) => {
            item.value = item.dictKey;
            item.label = item.dictValue;
          });
          this.detailSeverTypeList = list;
          console.log("res----->", res);
        }
      });
    },
    //审核确认
    submit() {
      this.$axios.post(examineService, this.dialogParma).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.$refs.list.getList();
          this.isDialog = false;
        }
      });
    },
    //删除
    deleteData(ids) {
      this.$axios.post(`${deleteService}?ids=${ids}`).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.$refs.list.getList();
        }
      });
    },
    //获取服务类别列表
    getInitInfo() {
      const code = "convenience_service_type";
      this.$axios.get(`${getInitInfo}?code=${code}`).then((res) => {
        console.log("res----->", res);
        if (res.code == 200) {
          const { convenienceServiceTypeList } = res.data;
          convenienceServiceTypeList.forEach((item) => {
            item.label = item.dictValue;
            item.value = item.dictKey;
          });
          this.severTypeList = convenienceServiceTypeList;
          console.log(
            "convenienceServiceTypeList----->",
            convenienceServiceTypeList
          );
        }
      });
    },
    toAdd() {
      this.$router.push({
        name: "convenientServiceEdit",
      });
    },
    //审核
    async auditData(id) {
      await this.initDialogParams();
      this.dialogParma.id = id;
      this.isDialog = true;
    },
    //初始化弹窗数据
    initDialogParams() {
      this.dialogParma = {
        id: "",
        status: "",
        auditRemarks: "",
      };
    },
    //编辑
    editData(id) {
      this.$router.push({
        name: "convenientServiceEdit",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.convenientService {
  box-sizing: border-box;
  height: 100%;
}
</style>
