//便民服务列表查询
const getServiceList = `/gateway/hc-serve/manageapi/convenience/getServiceList`;
//初始化信息
const getInitInfo = `/gateway/hc-serve/manageapi/convenience/getInitInfo`;
//删除接口
const deleteService = `/gateway/hc-serve/manageapi/convenience/deleteService`;

//新增-编辑便民服务
const saveServiceInfo = `/gateway/hc-serve/manageapi/convenience/saveServiceInfo`;
//详情
const getServiceInfoById = `/gateway/hc-serve/manageapi/convenience/getServiceInfoById`;
//详情
const examineService = `/gateway/hc-serve/manageapi/convenience/examineService`;
//详情分类
const getServiceTypeLv2 = `/gateway/hc-serve/manageapi/convenience/getServiceTypeLv2`;
//编辑手机号
const serviceEdit = `/gateway/hc-serve/manageapi/convenience/serviceEdit`;
export {
  getServiceList,
  getInitInfo,
  deleteService,
  saveServiceInfo,
  getServiceInfoById,
  examineService,
  getServiceTypeLv2,
  serviceEdit,
};
