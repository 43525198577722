import { mapHelper } from "@/utils/common.js";
// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);
// 审核状态
const auditStatus = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核失败",
  },
];

const { map: auditStatusMap, setOps: auditStatusOps } = mapHelper.setMap(auditStatus);
export {
  auditStatusMap,
  auditStatusOps,
};
